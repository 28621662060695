import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {CustomerService} from '../../services/customer.service';
import {FilterService, LazyLoadEvent, SortEvent} from 'primeng/api';
import {Table} from 'dexie';
import {buffer, bufferTime, bufferWhen, Subject, switchMap, takeUntil, tap, timer} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {NotificationService} from '../../services/notification.service';
import {FbFormObject} from '../../class/fb_form_object';
import {RightSidenavService} from '../../services/right-sidenav.service';
import {StoreService} from '../../services/store.service';
import {ExportToCsv} from 'export-to-csv';
import {TableColumnReorderEvent, TableLazyLoadEvent} from 'primeng/table';

export interface Country {
    name?: string;
    code?: string;
}

export interface Representative {
    name?: string;
    image?: string;
}

export interface Customer {
    id?: number;
    name?: string;
    country?: Country;
    company?: string;
    date?: string | Date;
    status?: string;
    activity?: number;
    representative?: Representative;
    verified?: boolean;
    balance?: number;
}

@Component({
    selector: 'app-fb-forms-table',
    templateUrl: './fb-forms-table.component.html',
    styleUrls: ['./fb-forms-table.component.scss']
})
export class FbFormsTableComponent implements OnInit, OnDestroy {

    public refresh = false;

    public data!: any[];
    public filteredData!: any[];

    public statuses!: any[];

    public loading = true;

    public settings: LazyLoadEvent | undefined;
    public isDirty = false;
    public dirtyFields = {};

    scrollable = true;

    @Input()
    public storeObj: FbFormObject | undefined;
    @Input()
    public storeTemplates = {};
    @Input()
    public storeExtObj = {};
    @Input()
    public edit_allowed = false;
    @Input()
    public show_add = false;
    @Input()
    public show_add_in_view = false;
    @Input()
    public show_delete = false;
    @Input()
    public show_select = false;
    @Input()
    public show_export = false;
    @Input()
    public title = '';
    @Input()
    public require_filter: any;
    @Input()
    public store_filter: any;

    @Input()
    public stateKey = 'stateKey';

    @Input()
    header_tpl: TemplateRef<any> | undefined;

    @Input()
    footer_tpl: TemplateRef<any> | undefined;

    @Input() get selectedColumns(): any[] {
        return this._selectedColumns;
    }

    @Input()
    public resizableColumns = true;

    @Input()
    public reorderableColumns = true;

    @Input()
    public rowsPerPageOptions = [10, 25, 50];

    @Input()
    public expandAble = false;
    @Input()
    expand_tpl: TemplateRef<any> | null;


    @Output() public row_click: EventEmitter<any> = new EventEmitter();
    @Output() public add_click: EventEmitter<any> = new EventEmitter();
    @Output() public dataLoaded: EventEmitter<any> = new EventEmitter();
    @Output() public after_save: EventEmitter<any> = new EventEmitter();
    @Output() public selectionChange: EventEmitter<any> = new EventEmitter();

    @ViewChild('dt') dataTableComponent: any | undefined;

    @ViewChild('add_form_tpl', {static: false}) add_form_tpl: TemplateRef<any> | undefined;
    @ViewChild('clear_form_tpl', {static: false}) clear_form_tpl: TemplateRef<any> | undefined;

    // forms
    public edit_fields_table: any[] | undefined;
    public default_model: any;
    public filter_fields: any[] | undefined;
    public addFields: any[] | undefined;
    public column_definition: any[] = [];
    public column_lookup = {};
    public field_types = {};
    public totalRecords: number | undefined;
    public edit = false;
    public selectedRows!: any[];
    public changed_rows: any[] = [];

    private lastTableLazyLoadEvent: TableLazyLoadEvent | undefined;

    public initDone = false;
    private lastFilterStr = '';

    public filterSubject: Subject<TableLazyLoadEvent>;
    public newRecord = {data: {}};
    public saving = false;
    public filterObj: any;
    public globalFilterFields: string[] = [];

    public expandedRows = {};
    public datakey = '';

    public globalFilterStr = '';
    private add_tpl_set = false;

    public subs = [];

    private clickSubject = new Subject<any>();
    private clickCount = 0;
    private rowSelect: any;
    private rowClickWithCtrlKey = false;
    private _selectedColumns: any[] = [];

    public cols: any[] = [];
    set selectedColumns(val: any[]) {
        if (val && val.length > 0 && this.cols && this.cols.length > 0
            && !!val[0] && !!this.cols[0]
        ) {
            const cols = [];
            for (let  i = 0; i < val.length; i++) {
                if (!val[i].id) {
                    cols.push(val[i]);
                } else {
                    cols.push(val[i].id);
                }
            }
            this._selectedColumns = cols;
            // this._selectedColumns = val;
        } else {
            const cols = [];
            for (let  i = 0; i < this.cols.length; i++) {
                cols.push(this.cols[i].id);
            }
            this._selectedColumns = cols;
        }
        localStorage.setItem(this.stateKey + '_selected_columns', JSON.stringify(val));

        this.updateColumnSelectionState();
    }
    constructor(private customerService: CustomerService,
                private filterService: FilterService,
                private cdr: ChangeDetectorRef,
                private notificationService: NotificationService,
                private rightSidenav: RightSidenavService,
                private storeService: StoreService) {

        this.subs.push(
            this.rightSidenav.OpenChanged().subscribe((open: boolean) => {
                if (!open) {
                    this.rightSidenav.setSideTemplate(this.clear_form_tpl);
                }
            })
        );
        this.filterSubject = new Subject<TableLazyLoadEvent>();
        this.filterSubject.pipe(debounceTime(300)).subscribe((event: TableLazyLoadEvent) => {
            this.loading = true;
            this.lastTableLazyLoadEvent = event;

            const settings = JSON.stringify(event);
            localStorage.setItem('t_', settings);

            // reset selectedRows on changes
            this.selectedRows = [];
            if (this.storeObj) {
                // dirty to init fields for filter that we send to api
                this.storeObj.custom_obj = this.storeExtObj;
                this.storeObj.setColumns();
                this.storeObj.setEditFields();
                this.storeObj.initFields();
                this.field_types = this.storeObj.getFieldTypes();

                const filter = '?' + this.filterToApiQuery(event);

                if (!this.initDone) {
                    this.lastFilterStr = filter;
                    this.storeObj.init(this.storeTemplates, this.storeExtObj, filter).then((success) => {
                        if (this.storeObj) {
                            this.datakey = 'data.' + this.storeObj.getStore().getIndexField();
                            this.default_model = this.storeObj.getDefaultModel();
                            this.filter_fields = this.storeObj.getEditFields();
                            this.column_definition = this.storeObj.getColumnDefinition();
                            this.column_lookup = this.storeObj.column_lookup;
                            this.edit_fields_table = this.storeObj.getEditFieldsTable();
                            this.field_types = this.storeObj.field_types;

                            for  (let i = 0; i < this.column_definition.length; i++) {
                                this.globalFilterFields.push(this.column_definition[i].id);
                            }

                            this.initDone = true;

                            this.setColumns();

                            this.cdr.detectChanges();
                            this.setRows();
                        }
                    });
                } else {
                    if (filter !== this.lastFilterStr) {
                        this.lastFilterStr = filter;
                        this.reloadTable();
                    } else {
                        // Todo: check event
                        console.warn('check event', event);
                        /*
                        if (event && event.filters && event.filters['global']) {
                            this.filterRowsGlobal(event.filters['global']?.value);
                        } else {
                            this.filteredData = this.data;
                        }
                        */
                        this.loading = false;
                    }
                }
            }
        });
    }

    ngOnDestroy(): void {
        if (this.subs.length > 0) {
            for (let i = 0; i < this.subs.length; i++) {
                this.subs[i].unsubscribe();
            }
        }
    }

    ngOnInit() {
        // this.customerService.getCustomersLarge().then((customers) => {
        //   this.customers = customers;
        //   // this.customers.forEach((customer) => (customer.date = new Date(<Date>customer.date)));
        // });

        const settings = localStorage.getItem('t_');
        if (settings && settings !== '') {
            this.settings = JSON.parse(settings);
        }
        // this.data = this.storeObj.getStore().getRecords();

        // Buffer clicks and emit after 1000ms of inactivity
        // Start buffering on the first click and reset the timer on each subsequent click
        this.clickSubject.pipe(
            // Reset the timer and buffer for 1000ms after each new click
            switchMap(() =>
                this.clickSubject.pipe(
                    buffer(timer(500)),  // Start a 1000ms timer to buffer clicks
                    tap(clicks => {
                        if (this.clickCount === 1) {
                            // Single click detected. Proceed clicks
                            if (this.row_click) {
                                this.row_click.emit({row: this.rowSelect.data, in_new_tab: this.rowClickWithCtrlKey});
                            }
                        } else if (this.clickCount > 1) {
                            // Multiple clicks detected. Ignoring clicks
                        }

                        // Buffer time exceeded after last click, and buffer reset
                        this.clickCount = 0;
                    })
                )
            )
        ).subscribe();
    }

    private setRows() {

        this.data = [];
        if (this.storeObj) {
            const store = this.storeObj?.getStore();
            if (store) {
                const records = store.getRecords();
                this.totalRecords = store.totalRecords;
                const indexField = store.getIndexField();

                const userTimezoneOffset = new Date().getTimezoneOffset() * 60000;
                const dateFields = this.storeObj.getAllDateFields();

                if (records.length > 0) {
                    for (let i = 0; i < records.length; i++) {
                        const rec = records[i];
                        this.prepareRow(rec, indexField, dateFields, userTimezoneOffset);
                    }
                }

                this.data = records;
                this.filteredData = records;
                this.loading = false;
                this.cdr.detectChanges();
                if (this.dataLoaded) {
                    this.dataLoaded.emit({totalRecords: this.totalRecords});
                }
            }
        }
    }

    private prepareRow(record: any, indexField: string, dateFields: any, userTimezoneOffset: number) {
        record.id = record.data[indexField];
        // if (dateFields.length > 0) {
        //     for (let d = 0; d < dateFields.length; d++) {
        //         const fieldName = dateFields[d];
        //         if (typeof record.data[fieldName] === 'string') {
        //             const date_str = record.data[fieldName] + 'T00:00:00.000Z';
        //             const org_date_str = record.org_data[fieldName] + 'T00:00:00.000Z';
        //             const date = new Date(new Date(date_str).getTime() - userTimezoneOffset * -1);
        //             const date_org = new Date(new Date(org_date_str).getTime() - userTimezoneOffset * -1);
        //             record.data[fieldName] = date;
        //             record.org_data[fieldName] = date_org;
        //         }
        //     }
        // }

        record['tmp'] = {
            caption: {}
        };

        // @ts-ignore
        const selectFields = this.storeObj.getFieldsByType('select');
        if (selectFields.length > 0) {
            for (let f = 0; f < selectFields.length; f++) {
                const fieldName = selectFields[f];
                // @ts-ignore
                record['tmp'].caption[fieldName] = this.column_lookup[fieldName].look_up[record.data[fieldName]];
            }
        }

        // @ts-ignore
        const autocompleteFields = this.storeObj.getFieldsByType('autocomplete');
        if (autocompleteFields.length > 0) {
            for (let f = 0; f < autocompleteFields.length; f++) {
                const fieldName = autocompleteFields[f];
                // @ts-ignore
                if (this.column_lookup[fieldName] && this.column_lookup[fieldName].look_up) {
                    record['tmp'].caption[fieldName] = this.column_lookup[fieldName].look_up[record.data[fieldName]];
                } else {
                    record['tmp'].caption[fieldName] = '';
                }
            }
        }

    }

    private filterRowsGlobal(value: string) {
        this.filteredData = this.data.filter((rec) => {
            let valueStr = '';
            for (const key in rec.data) {
                if (rec.data[key]) {
                    valueStr += String(rec.data[key]);
                }
            }
            for (const key in rec.tmp.caption) {
                if (rec.tmp.caption[key]) {
                    valueStr += String(rec.tmp.caption[key]);
                }
            }

            return JSON.stringify(valueStr).includes(value);
        });
        this.loading = false;
    }

    customFilterCallback(filter: (a: any) => void, value: any, field_type: string): void {
        // this.stopListening = true;
        filter(value);
        // this.stopListening = false;
    }

    public filterChanged(event: any) {
        // console.log('filterChanged', event);
    }

    lazyLoadData(event: TableLazyLoadEvent) {
        this.filterSubject.next(event);
    }

    private filterToApiQuery(filterObj: any) {
        this.filterObj = filterObj;

        let arFilter: any[] = [];
        let index = 0;
        if (this.require_filter) {
            arFilter = [...this.require_filter.filter];
            index = this.require_filter.index;
        }

        // console.log('filterObj', filterObj);
        for (const f in filterObj.filters) {
            if (f === 'global' && filterObj.filters[f].value) {
                this.globalFilterStr = filterObj.filters[f].value;
                arFilter.push('global=' + filterObj.filters[f].value);
            } else if (filterObj.filters.hasOwnProperty(f)) {
                for (let i = 0; i < filterObj.filters[f].length; i++) {
                    const filter = filterObj.filters[f][i];
                    if (filter.value === null || (filter.value.length === 0)) {
                        // do not filter for empty values
                    } else {
                        const fieldKey = f.replace('data.', '');
                        index++;
                        arFilter.push('filter[' + fieldKey + '][' + (index) + '][mode]=' + filter.matchMode);
                        arFilter.push('filter[' + fieldKey + '][' + (index) + '][operator]=' + filter.operator);

                        switch (filter.matchMode) {
                            default:
                                arFilter.push('filter[' + fieldKey + '][' + (index) + '][value]=' +
                                    // @ts-ignore
                                    this.parseFieldTypeValuesForFilter(this.field_types[fieldKey], filter.value));
                                break;
                            case 'in':
                                let valueIn = '';
                                if (filter.value && filter.value.length > 0) {
                                    const arValuesIn = [];
                                    for (let v = 0; v < filter.value.length; v++) {
                                        arValuesIn.push(filter.value[v]);
                                    }
                                    valueIn = arValuesIn.join(',');
                                }
                                arFilter.push('filter[' + fieldKey + '][' + (index) + '][value]=' + valueIn);
                                break;
                        }
                    }
                }
            }
        }
        arFilter.push('first=' + filterObj.first);
        arFilter.push('rows=' + filterObj.rows);

        if (filterObj.multiSortMeta && filterObj.multiSortMeta.length > 0) {
            for (let i = 0; i < filterObj.multiSortMeta.length; i++) {
                const sort = filterObj.multiSortMeta[i];
                const sortField = sort.field.replace('data.', '');
                const sortOrder = sort.order;
                arFilter.push('sort[' + i + '][' + sortField + ']=' + sortOrder);
            }
        }
        // ToDo: Sort
        return arFilter.join('&');
    }

    private parseFieldTypeValuesForFilter(field_type: string, value: any) {
        switch (field_type) {
            default:
                return value;
            case 'date':
                const dtDate = new Date(value);
                return dtDate.getFullYear() + '-' +
                    this.pad(dtDate.getMonth() + 1, 2) + '-' + this.pad(dtDate.getDate(), 2);
            case 'datetime':
                const dtDatetime = new Date(value);
                return dtDatetime.getFullYear() + '-' +
                    this.pad(dtDatetime.getMonth() + 1, 2) + '-' + this.pad(dtDatetime.getDate(), 2) + ' ' +
                    this.pad(dtDatetime.getHours(), 2) + ':' + this.pad(dtDatetime.getMinutes(), 2);
            case 'time':
                return value;
        }
    }

    private pad(num: number, size: number) {
        let s = num + '';
        while (s.length < size) {
            s = '0' + s;
        }
        return s;
    }

    onRowClick($event, row: any) {
        this.clickCount++;
        this.rowSelect = row;
        this.rowClickWithCtrlKey = $event.ctrlKey;
        this.clickSubject.next(row);
    }

    onRowDoubleClick(event: MouseEvent) {
        event.stopPropagation(); // Prevent double-click from selecting the row
    }

    addClicked() {
        if (this.add_click) {
            this.add_click.emit();
        }
    }

    addInTableClicked() {
        this.rebuildForm();
        // @ts-ignore
        this.rightSidenav.setSideTemplate(this.add_form_tpl);
        const sidenav = this.rightSidenav.getSidenav();
        sidenav.toggle();
    }

    onRowSelect(event: any) {
        // console.log('event', event);
    }

    onRowUnselect(event: any) {
        // console.log('event', event);
    }

    public saveRow(event: any) {
    }

    public showEdit() {
        this.edit = true;
        this.setRows();
        this.cdr.detectChanges();
    }

    public cancelRowInput() {
        this.edit = false;
        this.setRows();
        this.cdr.detectChanges();
        this.revertRows();
    }

    public addChangedRow(changed_row: any) {

        // @ts-ignore
        const indexKey = this.storeObj.getStore().getIndexField();
        const changed_rows = this.changed_rows.filter((row) => {
            // @ts-ignore
            return String(row.row.data[indexKey]) === String(changed_row.data[indexKey]);
        });

        if (changed_rows.length === 0) {
            this.changed_rows.push({
                row: changed_row
            });
        }
    }

    public saveRows() {
        this.storeService.is_saving = true;
        console.log('this.changed_rows', this.changed_rows);
        // @ts-ignore
        this.storeObj.saveAllChangedRows({rows: this.changed_rows}).then((success) => {
            if (success) {
                this.notificationService.success('Daten',
                    'Die Datensätze wurden erfolgreich gespeichert', {timeOut: 5000});
                this.changed_rows = [];
                this.storeService.is_saving = false;
                if (this.after_save) {
                    this.after_save.emit();
                }
                // this.filterSubject.next(this.lastTableLazyLoadEvent);
            } else {
                this.notificationService.error('Daten',
                    'Es konnten nicht alle Datansätze gespeichert werden', {timeOut: 5000});
                this.storeService.is_saving = false;
            }
        });
    }

    // public allRowsClean() {
    //     for (let i = 0; i < this.for)
    // }

    public revertRows() {
        this.loading = true;

        for (let i = 0; i < this.changed_rows.length; i++) {
            const record = this.changed_rows[i].row;
            // @ts-ignore
            this.storeObj.getStore().revertRecord(record);
        }
        this.changed_rows = [];
        this.loading = false;
    }

    public deleteSelection() {
        this.loading = true;
        // @ts-ignore
        this.storeObj.deleteRecords({_selected: this.selectedRows}).then((success) => {
            if (success) {
                this.notificationService.success('Daten',
                    'Die Daten wurden erfolgreich gelöscht', {timeOut: 5000});
                this.selectedRows = [];
                this.cdr.detectChanges();
                this.setRows();
                if (this.after_save) {
                    this.after_save.emit();
                }
            } else {
                this.notificationService.error('Daten',
                    'Es konnten nicht alle Daten gelöscht werden', {timeOut: 5000});
            }
        });
    }

    public saveStore(event: any) {
        this.saving = true;
        this.storeService.is_saving = true;
        // @ts-ignore
        this.storeObj.getStore().addRecord(this.newRecord.data, '').then((res) => {
            if (res && res.success) {
                this.notificationService.success('Data',
                    'Speichern erfolgreich.', {timeOut: 5000});
                this.reloadTable();

                if (this.after_save) {
                    this.after_save.emit();
                }
            } else {
                this.notificationService.error('Data',
                    'Speichern fehlgeschlagen.', {timeOut: 5000});
                this.saving = false;
                this.storeService.is_saving = false;
            }
        });
    }

    public rebuildForm() {
        this.addFields = [];
        this.resetModel();
        // @ts-ignore
        this.addFields = this.storeObj.getForm(this.newRecord.data);
        this.cdr.detectChanges();
    }

    private resetModel() {
        this.newRecord = this.storeObj.getDefaultModel();
    }

    customGlobalFilter(data: any[], filterValue: string) {
        if (!filterValue) {
            return data;
        }
        filterValue = filterValue.toLowerCase();

        return data.filter(item => {
            // Customize this logic based on your data structure
            // and filtering needs
            return Object.keys(item).some(key => {
                if (item[key] !== null && typeof item[key] === 'string') {
                    return item[key].toLowerCase().includes(filterValue);
                }
                return false;
            });
        });
    }

    onFilterChange(filterValue: string) {
        this.filteredData = this.customGlobalFilter(this.data, filterValue);
    }


    public ArrayToLookUpStringForLookUp(lookup: any, ar_values: any) {
        const res = [];
        if (Array.isArray(ar_values) && ar_values.length > 0) {
            for (let i = 0; i < ar_values.length; i++) {
                const looked_up = lookup[String(ar_values[i])];
                if (looked_up) {
                    res.push(looked_up);
                }
            }
        }
        return res.join(', ');
    }

    public toLookUpString(obj: any, key: any) {
        if (obj[key] && obj[key] !== '') {
            return String(obj[key]);
        }
        return null;
    }

    private ChipAutocompletValuesToLookUpString(lookup: any, comma_value: any) {
        if (comma_value) {
            const chip_value = comma_value.replaceAll(', ', ',');
            const ar_values = chip_value.split(',');
            return this.ArrayToLookUpStringForLookUp(lookup, ar_values);
        }
        return '';
    }

    public exportRows() {
        // @ts-ignore
        const data = [];
        // @ts-ignore
        const headers = [];

        const div = document.createElement('div');

        // let csvContent = '';
        // const header = [];
        // @ts-ignore
        for (let c = 0; c < this.column_definition.length; c++) {
            // @ts-ignore
            const column = this.column_definition[c];
            if (column.id !== '#fb_column_spacer' && column.id !== '#fb_column_select') {
                headers.push(column.label);
                switch (column.field_type) {
                    default:
                        break;
                    case 'select':
                    case 'select_group':
                    case 'chip_autocomplete':
                    case 'autocomplete':
                    case 'checkbox':
                        if (!column.do_not_export_klartext) {
                            headers.push('_Klartext_' + column.label);
                        }
                        break;
                }
            }
        }

        const options = {
            fieldSeparator: ';',
            quoteStrings: '"',
            decimalSeparator: ',',
            showLabels: true,
            showTitle: false,
            title: '',
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: false,
            headers: headers,
            filename: 'export'
        };

        // const row = header.join(';');
        // csvContent += row + '\r\n';
        const filterObj = this.filterObj;
        filterObj.rows = 10000;
        const filter = '?' + this.filterToApiQuery(filterObj);
        // @ts-ignore
        this.storeObj.getStore().loadStore(filter).then((records: any[]) => {
            const that = this;
            records.forEach(function (rowArray) {
                const body = [];
                const row = {};
                // @ts-ignore
                for (let c = 0; c < that.column_definition.length; c++) {
                    // @ts-ignore
                    const column = that.column_definition[c];
                    if (column.id !== '#fb_column_spacer' && column.id !== '#fb_column_select') {

                        let val = rowArray.data[column.id]; // .replace('\r', '').replace('\n', '')
                        if (String(val) !== undefined) {
                            val = String(val).replace('\r', '').replace('\n', '');
                        }

                        div.innerHTML = val;
                        val = div.textContent || div.innerText || '';
                        // body.push(val);
                        // @ts-ignore
                        row[column.label] = val;

                        if (!column.do_not_export_klartext) {
                            switch (column.field_type) {
                                default:
                                    break;
                                case 'select':
                                case 'select_group':
                                case 'autocomplete':
                                    // @ts-ignore
                                    const lookup = that.column_lookup[column.id].look_up;
                                    if (lookup && column.option_key) {
                                        if (column.option_multiple) {
                                            // multiple-select
                                            // @ts-ignore
                                            row['_Klartext_' + column.label] = that.ArrayToLookUpStringForLookUp(lookup,
                                                rowArray.data[column.option_key]);
                                        } else {
                                            const lookup_val = that.toLookUpString(rowArray.data, column.option_key);
                                            // select, autocomplete
                                            // @ts-ignore
                                            row['_Klartext_' + column.label] = lookup[lookup_val];
                                        }
                                    } else {
                                        // @ts-ignore
                                        row['_Klartext_' + column.label] = '';
                                    }
                                    break;
                                case 'chip_autocomplete':
                                    // @ts-ignore
                                    const lookup_chip_auto = that.column_lookup[column.id].look_up;
                                    // @ts-ignore
                                    row['_klartext_' + column.label] = that.ChipAutocompletValuesToLookUpString(lookup_chip_auto,
                                        rowArray.data[column.option_key]);
                                    break;
                                case 'checkbox':
                                    if (rowArray.data[column.id] && rowArray.data[column.id] !== '0') {
                                        // @ts-ignore
                                        row['_Klartext_' + column.label] = 'Ja';
                                    } else {
                                        // @ts-ignore
                                        row['_Klartext_' + column.label] = 'Nein';
                                    }
                                    break;
                            }
                        }
                    }
                }

                // const tmp_row = body.join(';');
                // csvContent += tmp_row + '\r\n';
                data.push(row);
            });

            const csvExporter = new ExportToCsv(options);
            // @ts-ignore
            csvExporter.generateCsv(data);

            /*
            const blob = new Blob([
                    new Uint8Array([0xEF, 0xBB, 0xBF]), // UTF-8 BOM
                    csvContent,
                ],
                { type: 'text/csv;charset=utf-8' });

            const pom = document.createElement('a');
            // var csvContent=csv; //here we load our csv data
            // let blob = new Blob([csvContent],{type: 'text/csv;charset=utf-8;'});
            const url = URL.createObjectURL(blob);
            pom.href = url;
            pom.setAttribute('download', 'export.csv');
            pom.click();
            */
        });


    }

    clear(table: any) {
        this.globalFilterStr = '';
        table.clear();
        table.clearState();
    }

    public splitValue(value: string, separator: string) {
        if (value) {
            return value.split(separator);
        }
        return [];
    }

    dirtyChanged($event: boolean, row: any) {
        this.dirtyFields[row.id] = $event;
        let tmpDirty = false;
        const dirtyFields = this.dirtyFields;
        Object.keys(dirtyFields).forEach(function(key, index) {
            if (dirtyFields[key]) {
                tmpDirty = true;
            }
        });
        this.isDirty = tmpDirty;
    }

    public reloadTable() {
        const store = this.storeObj.getStore();
        if (store) {
            store.invalidateStore();
            store.loadStore(this.lastFilterStr).then((records: any) => {
                this.setRows();
                this.saving = false;
                this.rebuildForm();
                this.storeService.is_saving = false;
            });
        }
    }

    rowSelectionChanged($event: any) {
        this.selectionChange.emit($event);
    }

    onStateSave($event: any) {
        if ($event.selection) {
            delete $event.selection;
        }
        sessionStorage.setItem(this.stateKey, JSON.stringify($event));
    }

    public onColumnReorder($event: TableColumnReorderEvent) {
        if ($event.columns && !!$event.columns[0]) {
            this.cols = $event.columns;
            localStorage.setItem(this.stateKey + '_sorted_columns', JSON.stringify(this.cols));
            // this.selectedColumns = this.cols;
            this.cdr.detectChanges();
        } else {
            console.warn('could not set colum order for ', $event.columns);
        }
    }

    private setColumns() {
        if (this.storeObj?.column_definition) {
            let ordered_columns = Object.assign([], this.storeObj.column_definition);

            const last_o_columns = localStorage.getItem(this.stateKey + '_sorted_columns');

            if (last_o_columns && last_o_columns !== '') {
                ordered_columns = JSON.parse(last_o_columns);
            }

            this.cols = ordered_columns.sort((a: any, b: any) => {
                const orderA = ordered_columns.findIndex((item: any) => item.id === a.id);
                const orderB = ordered_columns.findIndex((item: any) => item.id === b.id);
                return orderA - orderB;
            });

            const last_columns = localStorage.getItem(this.stateKey + '_selected_columns');
            this.selectedColumns = [];
            // init columns (workaround for none visible columns in table)
            // this.selectedColumnsInit = this.storeObj.column_definition;
            if (last_columns && last_columns !== '') {
                // override columns from local storage
                this.selectedColumns = JSON.parse(last_columns);
            }
            this.cdr.detectChanges();
        }
    }

    public expandRowClick($event, rowData: any) {
        $event?.stopPropagation();
        this.dataTableComponent.toggleRow(rowData);
    }

    public clearFilter(table: any) {
        this.globalFilterStr = '';
        table.clear();
    }

    public clearTableSettings() {
        this.refresh = true;
        localStorage.removeItem(this.stateKey);
        localStorage.removeItem(this.stateKey + '_sorted_columns');
        localStorage.removeItem(this.stateKey + '_selected_columns');

        setTimeout( () => {
            this.setColumns();
            this.setRows();
            this.refresh = false;
            this.cdr.detectChanges();
        }, 300);

    }

    private findCircularReference(obj: any, path = 'root', seen = new WeakMap<any, string>()): string | null {
        if (obj && typeof obj === 'object') {
            if (seen.has(obj)) {
                return `Circular reference detected at ${path}, previously seen at ${seen.get(obj)}`;
            }
            seen.set(obj, path);
            for (const key in obj) {
                if (Object.prototype.hasOwnProperty.call(obj, key)) {
                    const result = this.findCircularReference(obj[key], `${path}.${key}`, seen);
                    if (result) {
                        return result;
                    }
                }
            }
        }
        return null;
    }

    private updateColumnSelectionState() {
        const idsSet = new Set(this.selectedColumns);
        for (const item of this.cols) {
            // Mutating the item directly
            item['selected'] = idsSet.has(item.id);
        }
    }
}
