import {FbFormObject} from '../class/fb_form_object';
import {AppSettings} from '../config/AppSettings';

export class LapTimeUnconfirmed extends FbFormObject {

    /**
     * override default columns
     */
    public setColumns() {
        this.column_definition = [
            {id: 'track_id', label: 'Track', visible: true, noclick: false, disable_ripple: true},
            {id: 'race_type_id', label: 'Type', visible: true, noclick: false, disable_ripple: true},
            {id: 'user_id', label: 'Player', visible: true, noclick: false, disable_ripple: true},
            {id: 'lap_time', label: 'Lap time', visible: true, noclick: false, disable_ripple: true},
            {id: 'vehicle_id', label: 'Vehicle', visible: true, noclick: false, disable_ripple: true},
            {id: 'man_id', label: 'Manufacturer', visible: true, noclick: false, disable_ripple: true},
            {id: 'vehicle_type', label: 'Vehicle Type', visible: true, noclick: false, disable_ripple: true},
            {id: 'vehicle_class', label: 'Vehicle Class', visible: true, noclick: false, disable_ripple: true},
            {id: 'control_stick_left', label: 'Stick left', visible: true, noclick: false, disable_ripple: true},
            {id: 'control_stick_right', label: 'Stick right', visible: true, noclick: false, disable_ripple: true},
            {id: 'control_throttle', label: 'Throttle', visible: true, noclick: false, disable_ripple: true},
            {id: 'control_pedals', label: 'Pedals', visible: true, noclick: false, disable_ripple: true},
            {id: 'control_keyboard', label: 'Keyboard', visible: true, noclick: false, disable_ripple: true},
            {id: 'control_mouse', label: 'Mouse', visible: true, noclick: false, disable_ripple: true},
            {id: 'assist_coupled', label: ' coupled', visible: true, noclick: false, disable_ripple: true},
            {id: 'assist_gravity_compensation', label: 'gravity compensation', visible: true, noclick: false, disable_ripple: true},
            {id: 'assist_gsafe', label: 'gsafe', visible: true, noclick: false, disable_ripple: true},
            {id: 'assist_proximity', label: 'proximity assist', visible: true, noclick: false, disable_ripple: true},
            {id: 'insert_ts', label: 'Inserted', visible: true, noclick: false, disable_ripple: true},
            {id: 'confirmed', label: 'Confirmed', visible: true, noclick: false, disable_ripple: true}
        ];
    }

    /**
     * override default fields
     */
    public setEditFields() {
        this.edit_fields = [
            {
                caption: 'Track',
                class: 'card card-1 padding',
                field_class: '',
                fields: [
                    {
                        type: 'select',
                        name: 'track_id',
                        options: this.select_boxes['race_track_select'],
                        rules: [
                            {rule: 'required'}
                        ]
                    },
                    {
                        type: 'select',
                        name: 'race_type_id',
                        options: this.select_boxes['race_type_select'],
                        rules: [
                            {rule: 'required'}
                        ]
                    },
                    {
                        type: 'autocomplete',
                        name: 'user_id',
                        options: this.select_boxes['sc_user_select'],
                        disabled: true,
                        rules: [
                            {rule: 'required'}
                        ]
                    },
                    {
                        type: 'time',
                        name: 'lap_time',
                        step: 0.01
                    },
                    {
                        type: 'select',
                        name: 'vehicle_id',
                        options: this.select_boxes['vehicle_select'],
                        rules: [
                            {rule: 'required'}
                        ]
                    },
                    {
                        type: 'select',
                        name: 'man_id',
                        options: this.select_boxes['manufacturer_select'],
                        rules: [
                            {rule: 'required'}
                        ]
                    },
                    {
                        type: 'select',
                        name: 'vehicle_type',
                        options: this.select_boxes['vehicle_type_select'],
                        rules: [
                            {rule: 'required'}
                        ]
                    },
                    {
                        type: 'select',
                        name: 'vehicle_class',
                        options: this.select_boxes['vehicle_class_select'],
                        rules: [
                            {rule: 'required'}
                        ]
                    },
                    {
                        type: 'datetime',
                        name: 'insert_ts'
                    }
                ]
            },
            {
                caption: 'Controls (optional)',
                class: 'card card-1 padding',
                field_class: '',
                fields: [
                    {
                        type: 'checkbox',
                        name: 'control_stick_left'
                    },
                    {
                        type: 'checkbox',
                        name: 'control_stick_right'
                    },
                    {
                        type: 'checkbox',
                        name: 'control_throttle'
                    },
                    {
                        type: 'checkbox',
                        name: 'control_pedals'
                    },
                    {
                        type: 'checkbox',
                        name: 'control_keyboard'
                    },
                    {
                        type: 'checkbox',
                        name: 'control_mouse'
                    }
                ]
            },
            {
                caption: 'Assistants (optional)',
                class: 'card card-1 padding',
                field_class: '',
                fields: [
                    {
                        type: 'checkbox',
                        name: 'assist_coupled'
                    },
                    {
                        type: 'checkbox',
                        name: 'assist_gravity_compensation'
                    },
                    {
                        type: 'checkbox',
                        name: 'assist_gsafe'
                    },
                    {
                        type: 'checkbox',
                        name: 'assist_proximity'
                    }
                ]
            },
            {
                caption: 'Confirm',
                class: 'card card-1 padding',
                field_class: '',
                fields: [
                    {
                        type: 'checkbox',
                        name: 'confirmed',
                        restricted_groups: [AppSettings.group_rally_admin],
                        restrict_only_edit: true
                    }
                ]
            }
        ];
        super.setEditFields();
    }

    public loadStores(ext_obj?: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            // this.stores['lap_times'] = this.storeService.getStore('lap_times');

            this.stores['lap_times'] = this.storeService.createNoneGlobalStore('sc_ore',
                'race/lap-times-unconfirmed', 'rt_id');


            this.main_store = this.stores['lap_times'];

            this.stores['race_tracks'] = this.storeService.getStore('race_tracks');
            this.stores['race_types'] = this.storeService.getStore('race_types');
            this.stores['vehicle'] = this.storeService.getStore('vehicle');
            this.stores['manufacturer'] = this.storeService.getStore('manufacturer');
            this.stores['vehicle_types'] = this.storeService.getStore('vehicle_types');
            this.stores['vehicle_class'] = this.storeService.getStore('vehicle_class');
            this.stores['sc_user'] = this.storeService.getStore('sc_user');

            const selectFilter = [];
            selectFilter.push('first=0');
            selectFilter.push('rows=1000');

            const filter = [];

            const stores = [];
            stores.push(this.stores['sc_user'].loadStore('?' + selectFilter.join('&')));
            stores.push(this.stores['lap_times'].loadStore('?' + filter.join('&')));
            stores.push(this.stores['race_tracks'].loadStore('?' + selectFilter.join('&')));
            stores.push(this.stores['race_types'].loadStore('?' + selectFilter.join('&')));
            stores.push(this.stores['vehicle'].loadStore('?' + selectFilter.join('&')));
            stores.push(this.stores['manufacturer'].loadStore('?' + selectFilter.join('&')));
            stores.push(this.stores['vehicle_types'].loadStore('?' + selectFilter.join('&')));
            stores.push(this.stores['vehicle_class'].loadStore('?' + selectFilter.join('&')));

            Promise.all(stores).then((result) => {
                resolve(true);
            });
        });
    }

    public getDefaultModel() {
        return {
            data: {
                track_id: 0,
                race_type_id: 0,
                vehicle_id: 0,
                man_id: 0,
                vehicle_type: '',
                vehicle_class: '',
                lap_time: 0,
                control_stick_left: 0,
                control_stick_right: 0,
                control_throttle: 0,
                control_pedals: 0,
                control_keyboard: 0,
                control_mouse: 0
            }
        };
    }

    /**
     * custom reload
     */
    public reload(): Promise<any> {
        return this.getStore().loadStore('');
    }

    /**
     * override select_boxes
     */
    public setSelectboxes() {
        this.parseScUserSelectbox();
        this.parseTrackSelectBox();
        this.parseTypeSelectBox();
        this.parseVehicleSelectBox();
        this.parseManufacturerSelectBox();
        this.parseVehicleClassSelectBox();
        this.parseVehicleTypeSelectBox();

        console.log('this.select_boxes', this.select_boxes);
        // call super
        super.setSelectboxes();
    }

    public parseTrackSelectBox() {
        const options = [];
        const records = this.stores['race_tracks'].getRecords();
        for (let i = 0; i < records.length; i++) {
            options.push({
                value: String(records[i].data.track_id),
                name: records[i].data.track_name
            });
        }
        this.select_boxes['race_track_select'] = options;
        return this.select_boxes['race_track_select'];
    }

    public parseTypeSelectBox() {
        const options = [];
        const records = this.stores['race_types'].getRecords();
        for (let i = 0; i < records.length; i++) {
            options.push({
                value: String(records[i].data.type_id),
                name: records[i].data.type_name
            });
        }
        this.select_boxes['race_type_select'] = options;
        return this.select_boxes['race_type_select'];
    }

    public parseVehicleSelectBox() {
        const options = [];
        const records = this.stores['vehicle'].getRecords();
        for (let i = 0; i < records.length; i++) {
            options.push({
                value: String(records[i].data.vehicle_id),
                name: records[i].data.vehicle_name
            });
        }
        this.select_boxes['vehicle_select'] = options;
        return this.select_boxes['vehicle_select'];
    }

    public parseManufacturerSelectBox() {
        const options = [];
        const records = this.stores['manufacturer'].getRecords();
        for (let i = 0; i < records.length; i++) {
            options.push({
                value: String(records[i].data.man_id),
                name: records[i].data.man_short + ' - ' + records[i].data.man_name
            });
        }
        this.select_boxes['manufacturer_select'] = options;
        return this.select_boxes['manufacturer_select'];
    }

    public parseVehicleTypeSelectBox() {
        const options = [];
        const records = this.stores['vehicle_types'].getRecords();
        for (let i = 0; i < records.length; i++) {
            options.push({
                value: String(records[i].data.vehicle_type),
                name: records[i].data.type_name
            });
        }
        this.select_boxes['vehicle_type_select'] = options;
        return this.select_boxes['vehicle_type_select'];
    }

    public parseVehicleClassSelectBox() {
        const options = [];
        const records = this.stores['vehicle_class'].getRecords();
        for (let i = 0; i < records.length; i++) {
            options.push({
                value: String(records[i].data.vehicle_class),
                name: records[i].data.class_name
            });
        }
        this.select_boxes['vehicle_class_select'] = options;
        return this.select_boxes['vehicle_class_select'];
    }

    public parseScUserSelectbox() {
        const options = [];
        const records = this.stores['sc_user'].getRecords();
        for (let i = 0; i < records.length; i++) {
            options.push({
                value: String(records[i].data.user_id),
                name: records[i].data.sc_username
            });
        }
        this.select_boxes['sc_user_select'] = options;
        return this.select_boxes['sc_user_select'];
    }

}
