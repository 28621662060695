import {FbFormObject} from '../class/fb_form_object';

export class UexPriceDetail extends FbFormObject {

    /**
     * override default columns
     */
    public setColumns() {
        this.column_definition = [
            // {id: 'commodity_name', label: 'Name', visible: true, noclick: false, disable_ripple: true},
            {id: 'terminal_name', label: 'Terminal', visible: true, noclick: false, disable_ripple: true},
            {id: 'price_buy', label: 'buy', visible: true, noclick: false, disable_ripple: true},
            {id: 'price_buy_avg', label: 'buy avg', visible: true, noclick: false, disable_ripple: true},
            {id: 'price_sell', label: 'sell', visible: true, noclick: false, disable_ripple: true},
            {id: 'price_sell_avg', label: 'sell avg', visible: true, noclick: false, disable_ripple: true},
            {id: 'scu_buy', label: 'scu buy', visible: true, noclick: false, disable_ripple: true},
            {id: 'scu_buy_avg', label: 'scu buy avg', visible: true, noclick: false, disable_ripple: true},
            {id: 'scu_sell_stock', label: 'scu sell stock', visible: true, noclick: false, disable_ripple: true},
            {id: 'scu_sell_stock_avg', label: 'scu sell stock avg', visible: true, noclick: false, disable_ripple: true},
            {id: 'scu_sell', label: 'scu sell', visible: true, noclick: false, disable_ripple: true},
            {id: 'scu_sell_avg', label: 'scu sell avg', visible: true, noclick: false, disable_ripple: true},
            {id: 'status_buy', label: 'status buy', visible: true, noclick: false, disable_ripple: true},
            {id: 'status_sell', label: 'status sell', visible: true, noclick: false, disable_ripple: true},
            {id: 'container_sizes', label: 'container sizes', visible: true, noclick: false, disable_ripple: true}
        ];
    }

    /**
     * override default fields
     */
    public setEditFields() {
        this.edit_fields = [
            {
                caption: 'Cargo',
                class: 'card card-1 padding',
                field_class: '',
                fields: [
                    // {
                    //     type: 'text',
                    //     name: 'commodity_name',
                    //     disabled: true
                    // },
                    {
                        type: 'text',
                        name: 'terminal_name',
                        disabled: true
                    },
                    {
                        type: 'number',
                        name: 'price_buy',
                        disabled: true
                    },
                    {
                        type: 'number',
                        name: 'price_buy_avg',
                        disabled: true
                    },
                    {
                        type: 'number',
                        name: 'price_sell',
                        disabled: true
                    },
                    {
                        type: 'number',
                        name: 'price_sell_avg',
                        disabled: true
                    },
                    {
                        type: 'number',
                        name: 'scu_buy',
                        disabled: true
                    },
                    {
                        type: 'number',
                        name: 'scu_buy_avg',
                        disabled: true
                    },
                    {
                        type: 'number',
                        name: 'scu_sell_stock',
                        disabled: true
                    },
                    {
                        type: 'number',
                        name: 'scu_sell_stock_avg',
                        disabled: true
                    },
                    {
                        type: 'number',
                        name: 'scu_sell',
                        disabled: true
                    },
                    {
                        type: 'number',
                        name: 'scu_sell_avg',
                        disabled: true
                    },
                    {
                        type: 'checkbox',
                        name: 'status_buy',
                        disabled: true
                    },
                    {
                        type: 'checkbox',
                        name: 'status_sell',
                        disabled: true
                    },
                    {
                        type: 'text',
                        name: 'container_sizes',
                        disabled: true
                    }
                ]
            }
        ];

        super.setEditFields();
    }

    public loadStores(ext_obj?: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {

            this.stores['uex_price_details'] = this.storeService.createNoneGlobalStore('uex_price_details',
                'uex/commodity-averages/' + this.custom_obj.uex_id + '/price_details', 'id');

            this.main_store = this.stores['uex_price_details'];

            const stores = [];
            if (!this.stores['uex_price_details'].isLoaded()) {
                stores.push(this.stores['uex_price_details'].loadStore(this.filter));
            }

            Promise.all(stores).then((result) => {
                // all stores loaded
                resolve(true);
            });
        });
    }

    public getDefaultModel() {
        return {
            data: {
            }
        };
    }

    /**
     * override select_boxes
     */
    public setSelectboxes() {
        // call super
        super.setSelectboxes();
    }
}
