import {FbFormObject} from '../class/fb_form_object';

export class ScCargo extends FbFormObject {

    /**
     * override default columns
     */
    public setColumns() {
        this.column_definition = [
            {id: 'com_id', label: 'Id', visible: true, noclick: false, disable_ripple: true},
            {id: 'version_id', label: 'Version', visible: true, type: 'template', template_row: this.templates['select_caption_tpl'],
                noclick: false, disable_ripple: true},
            {id: 'uex_id', label: 'UEX Commodity', visible: true, noclick: false, disable_ripple: true},
            {id: 'name', label: 'Name', visible: true, noclick: false, disable_ripple: true},
            {id: 'price', label: 'Price', visible: true, noclick: false, disable_ripple: true},
            {id: 'description', label: 'Description', visible: true, noclick: false, disable_ripple: true},
            {id: 'tier', label: 'Tier', visible: true, noclick: false, disable_ripple: true},
            {id: 'is_illegal', label: 'Illegal', visible: true, noclick: false, disable_ripple: true},
        ];
    }

    /**
     * override default fields
     */
    public setEditFields() {
        this.edit_fields = [
            {
                caption: 'Cargo',
                class: 'card card-1 padding',
                field_class: '',
                fields: [
                    {
                        type: 'number',
                        name: 'com_id',
                        disabled: true
                    },
                    {
                        name: 'version_id',
                        type: 'select',
                        options: this.select_boxes['sc_version_select']
                    },
                    {
                        name: 'uex_id',
                        type: 'autocomplete',
                        options: this.select_boxes['uex_commodities_select']
                    },
                    {
                        type: 'text',
                        name: 'name',
                        on_other_field_changed: (self, changed_field, setFieldValueCallback, refreshFormField) => {
                            if (changed_field.name === 'uex_id') {
                                if (changed_field.model.obj.uex_id !== '') {
                                    const record = this.stores['uex_commodities'].getRecordById(changed_field.model.obj.uex_id);
                                    setFieldValueCallback(self, record.data.name);
                                } else {
                                    setFieldValueCallback(self, '');
                                }
                            }
                        },
                    },
                    {
                        type: 'number',
                        name: 'price',
                        rules: [
                            {rule: 'required'}
                        ],
                        on_other_field_changed: (self, changed_field, setFieldValueCallback, refreshFormField) => {
                            if (changed_field.name === 'uex_id') {
                                if (changed_field.model.obj.uex_id !== '') {
                                    const record = this.stores['uex_commodities'].getRecordById(changed_field.model.obj.uex_id);
                                    setFieldValueCallback(self, record.data.price_sell_avg);
                                } else {
                                    setFieldValueCallback(self, '');
                                }
                            }
                        },
                    },
                    {
                        type: 'textarea',
                        name: 'description',
                    },
                    {
                        type: 'number',
                        name: 'tier',
                        rules: [
                            {rule: 'required'}
                        ]
                    },
                    {
                        type: 'checkbox',
                        name: 'is_illegal'
                    }
                ]
            }
        ];

        super.setEditFields();
    }

    public loadStores(ext_obj?: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {

            this.stores['sc_cargo'] = this.storeService.createNoneGlobalStore('sc_cargo',
                'games/starcitizen/version/' + this.custom_obj.version_id + '/commodities/cargo', 'com_id');

            this.stores['sc_version'] = this.storeService.getStore('sc_version');
            this.stores['uex_commodities'] = this.storeService.getStore('uex_commodities');


            this.main_store = this.stores['sc_cargo'];

            const selectFilter = [];
            selectFilter.push('first=0');
            selectFilter.push('rows=1000');

            const stores = [];
            if (!this.stores['sc_cargo'].isLoaded()) {
                stores.push(this.stores['sc_cargo'].loadStore(this.filter));
            }
            if (!this.stores['sc_version'].isLoaded()) {
                stores.push(this.stores['sc_version'].loadStore('?' + selectFilter.join('&')));
            }
            if (!this.stores['uex_commodities'].isLoaded()) {
                stores.push(this.stores['uex_commodities'].loadStore('?' + selectFilter.join('&')));
            }

            Promise.all(stores).then((result) => {
                // all stores loaded
                resolve(true);
            });
        });
    }

    public getDefaultModel() {
        return {
            data: {
                version_id: 0,
                name: '',
                price: 0,
                description: '',
                cost_per_unit: 4,
                yield: 0.95,
                density: 0,
                instability: 0,
                resistance : 0,
                opt_window_midpoint: 0,
                opt_window_randomness: 0,
                opt_window_thinness: 0,
                explosion_multiplier : 0,
                cluster_factor: 0
            }
        };
    }

    /**
     * custom reload
     */
    public reload(): Promise<any> {
        return this.getStore().loadStore('');
    }

    /**
     * override select_boxes
     */
    public setSelectboxes() {
        this.parseVersionSelectbox();
        this.parseUexSelectBox();
        // call super
        super.setSelectboxes();
    }

    public parseVersionSelectbox() {
        const options = [];
        const records = this.stores['sc_version'].getRecords();
        for (let i = 0; i < records.length; i++) {
            options.push({
                value: records[i].data.version_id,
                name: records[i].data.version_name
            });
        }
        this.select_boxes['sc_version_select'] = options;
        return this.select_boxes['sc_version_select'];
    }

    public parseUexSelectBox() {
        const options = [];
        const records = this.stores['uex_commodities'].getRecords();
        for (let i = 0; i < records.length; i++) {
            const rec = records[i];
            if (!rec.data.is_raw) {
                options.push({
                    value: String(rec.data.id),
                    name: rec.data.name
                });
            }
        }
        this.select_boxes['uex_commodities_select'] = options;
        return this.select_boxes['uex_commodities_select'];
    }
}
