import {AfterViewInit, ChangeDetectorRef, Component, Input} from '@angular/core';
import {StoreService} from '../../services/store.service';
import {UexPriceDetail} from '../../forms/uex-price-detail';
import {AuthService} from '../../services/auth.service';


@Component({
    selector: 'app-commodity-price-details',
    templateUrl: './commodity-price-details.component.html',
    styleUrl: './commodity-price-details.component.css'
})
export class CommodityPriceDetailsComponent implements AfterViewInit {

    @Input()
    public uex_id = 0;

    public UexPriceDetail: UexPriceDetail;

    constructor(private storeService: StoreService,
                private authService: AuthService,
                private cdr: ChangeDetectorRef) {

    }

    ngAfterViewInit(): void {

        this.UexPriceDetail = new UexPriceDetail(this.storeService, this.authService, 'edit');
        this.cdr.detectChanges();

        // priceStore.loadStore('?rows=100').then((records: any[]) => {
        //     console.log('records', records);
        // });
    }

}
