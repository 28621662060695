import {AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy} from '@angular/core';
import {StoreService} from '../../../../../services/store.service';
import {AuthService} from '../../../../../services/auth.service';
import {Router} from '@angular/router';
import {NotificationService} from '../../../../../services/notification.service';
import {MatDialog} from '@angular/material/dialog';
import {IMqttMessage, MqttService} from 'ngx-mqtt';
import {Store} from '../../../../../class/store';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-bot-event-summary',
  templateUrl: './bot-event-summary.component.html',
  styleUrls: ['./bot-event-summary.component.css']
})
export class BotEventSummaryComponent implements OnDestroy, AfterViewInit {

  @Input()
  public event_id = 0;

  private SummaryStore: Store;

  public record: any[];
  public summaryRows = [];
  private subs: Array<Subscription> = [];

  constructor(private storeService: StoreService,
              private authService: AuthService,
              private router: Router,
              private cdr: ChangeDetectorRef,
              private notificationService: NotificationService,
              public dialog: MatDialog,
              private _mqttService: MqttService) {

  }

  ngAfterViewInit(): void {
    this.SummaryStore = this.storeService.createNoneGlobalStore('event-summary',
        'games/starcitizen/events/' + String(this.event_id) + '/summary', 'sum_profit');
    this.SummaryStore.loadStore('').then((records: any[]) => {
      this.record = records[0];
      this.summaryToArray(records[0].data.user_summary);
    });

    this.subs.push(this._mqttService.observe('bot/event').subscribe((message: IMqttMessage) => {
      const info = JSON.parse(message.payload.toString());
      if (Number(info.event_id) === Number(this.event_id)) {
        this.SummaryStore.loadStore('').then((records: any[]) => {
          this.record = records[0];
          this.summaryToArray(records[0].data.user_summary);
        });
      }
    }));
  }

  private summaryToArray(records: any) {
    const rows = [];
    for (const [key, value] of Object.entries(records)) {
      rows.push(value);
    }
    this.summaryRows = rows;
  }

  public getRowColor(row: any) {
    if (row.payment > 0) {
      return '#ffab00';
    }
    return '#00c853';
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }
}
