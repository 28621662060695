<ng-container *ngIf="uex_id && UexPriceDetail">
    <div class="card-1 card padding">
    <app-fb-forms-table [storeObj]="UexPriceDetail"
                        [edit_allowed]="false"
                        [stateKey]="'price_details_all'"
                        [show_add]="false"
                        [show_delete]="false"
                        [title]="'Price Details'"
                        [storeExtObj]="{uex_id: uex_id}"
    >
    </app-fb-forms-table>
    </div>
</ng-container>