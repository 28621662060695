import {StoreService} from '../services/store.service';
import {Store} from './store';
import {AuthService} from '../services/auth.service';
import {AppSettings} from '../config/AppSettings';

export class DynamicFormObject {

    public column_definition: any[] = [];
    public column_lookup = {};
    public edit_fields: any[] = [];
    public edit_fields_table: any[] = [];
    public templates = {};
    public select_boxes: any = {};
    public stores: any = {};
    public main_store: Store | undefined;
    public mode = 'edit';
    public default_model = {data: {}};
    public custom_error_callback: any;
    public value_lookup = {};
    public custom_obj: any;
    public field_types = {};
    public filter = '';

    constructor(public storeService: StoreService, public authService: AuthService, mode: string) {
        this.mode = mode;
    }

    /**
     * load forms and init all fields/data
     * must be called
     */
    public init(templates: any, custom_obj?: any, filter?: any): Promise<any> {
        this.custom_obj = custom_obj;
        if (filter) {
            this.filter = filter;
        }
        return new Promise<any>((resolve, reject) => {
            this.setTemplates(templates);
            this.loadStores(custom_obj).then((result) => {
                this.setSelectboxes();
                this.setColumns();
                this.setEditFields();
                this.initFields();
                resolve(true);
            });
        });
    }

    public loadStores(extra_obj?: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            resolve(true);
        });
    }

    public initFields() {
        // set placeholder for forms
        if (this.column_definition.length > 0) {
            for (let i = 0; i < this.column_definition.length; i++) {
                const column: any = this.column_definition[i];
                for (let c = 0; c < this.edit_fields.length; c++) {
                    // @ts-ignore
                    for (let f = 0; f < this.edit_fields[c].fields.length; f++) {
                        // @ts-ignore
                        const field = this.edit_fields[c].fields[f];
                        if (field && field.name === column.id) {
                            field['placeholder'] = column.label;
                            field['hidden'] = false;
                            field['noTable'] = column.noTable;
                            column['field_type'] = field.type;
                            column['number_formatted'] = false;
                            if (field.number_formatted) {
                                column['number_formatted'] = field.number_formatted;
                            }
                            column['field'] = field;
                            // this.column_definition[i]['type'] = field.type;
                            this.setFieldLookUp(column, field);
                            // @ts-ignore
                            this.column_lookup[column.id] = column;
                            if (field.restricted_groups && field.restricted_groups.length > 0) {
                                if (field.restrict_only_edit) {
                                    field.disabled = true;
                                } else {
                                    field['hidden'] = true;
                                }

                                for (let r = 0; r < field.restricted_groups.length; r++) {
                                    if (this.authService.hasRole(AppSettings.ifa_guild_id, field.restricted_groups[r])) {
                                        field['hidden'] = false;
                                        field.disabled = false;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        this.initFieldTypes();
        // ToDo: set groups for forms
    }

    public initFieldTypes() {
        for (let c = 0; c < this.edit_fields.length; c++) {
            // @ts-ignore
            for (let f = 0; f < this.edit_fields[c].fields.length; f++) {
                // @ts-ignore
                const field = this.edit_fields[c].fields[f];
                // @ts-ignore
                this.field_types[field.name] = field.type;
            }
        }
    }

    public setTemplates(templates: any) {
        this.templates = templates;
    }
    public setSelectboxes() {
    }
    public setColumns() {
    }
    public setEditFields() {
        this.edit_fields_table = [];
        if (this.edit_fields.length > 0) {
            // groups
            for (let g = 0; g < this.edit_fields.length; g++) {
                const group = this.edit_fields[g];
                // fields
                // @ts-ignore
                for (let f = 0; f < group.fields.length; f++) {
                    // @ts-ignore
                    this.edit_fields_table.push(group.fields[f]);
                }
            }
        }
    }

    public setFieldLookUp(column: any, field: any) {
        column['display_value'] = false;
        column['option_key'] = column.id;
        if (field.options && field.options.length) {
            column.look_up = {};
            column['select_options'] = field.options;
            for (let i = 0; i < field.options.length; i++) {
                const option = field.options[i];
                column.look_up[String(option.value)] = option.name;
            }
        }
        if (field.select_options && field.select_options.length) {
            column.look_up = {};
            column['select_options'] = field.select_options;
            for (let i = 0; i < field.select_options.length; i++) {
                const option = field.select_options[i];
                column.look_up[String(option.value)] = option.name;
            }
        }

        if (field.select_groups && field.select_groups.length) {
            column.look_up = {};
            column['select_groups'] = field.select_groups;
            for (let g = 0; g < field.select_groups.length; g++) {
                for (let i = 0; i < field.select_groups[g].items.length; i++) {
                    const option = field.select_groups[g].items[i];
                    column.look_up[String(option.value)] = option.label;
                }
            }
        }
        if (field.type === 'mask') {
            column['mask'] = field.mask;
            column['dataType'] = field.dataType;
        }
        if (field.display_value) {
            column['display_value'] = field.display_value;
        }
        if (field.option_key) {
            column['option_key'] = field.option_key;
        }
        if (field.do_not_export_klartext) {
            column['do_not_export_klartext'] = true;
        }
        if (column.look_up && field.multiple) {
            column['option_multiple'] = true;
        }
    }

    public getStore() {
        return this.main_store;
    }

    public getColumnDefinition() {
        return this.column_definition;
    }

    public getFieldTypes() {
        return this.field_types;
    }

    public getAllDateFields() {
        return this.getFieldsByType('date');
    }

    public getFieldsByType(type: string) {
        const arFields = [];
        const fieldTypes = this.field_types;
        // tslint:disable-next-line:forin
        for (const k in fieldTypes) {  // const k: string
            // @ts-ignore
            const v = fieldTypes[k];
            if (v === type) {
                arFields.push(k);
            }
        }
        return arFields;
    }


    public getEditFieldsTable() {
        return this.edit_fields_table;
    }

    public getEditFields() {
        return this.edit_fields;
    }

    public getDefaultModel() {
        return this.default_model;
    }

    public getForm(model: any) {
        if (this.edit_fields.length > 0) {
            // groups
            for (let g = 0; g < this.edit_fields.length; g++) {
                const group = this.edit_fields[g];
                // fields
                // @ts-ignore
                for (let f = 0; f < group.fields.length; f++) {
                    // @ts-ignore
                    const field = group.fields[f];
                    field['model'] = {obj: model, key: field.name};
                    field['placeholder'] = this.getNameForField(field.name);
                    field['description'] = this.getDescriptionForField(field.name);
                }
            }
        }
        return this.edit_fields;
    }

    public getFormOnlyInModel(field_list: any, model: any) {
        const fields = [];
        if (this.edit_fields.length > 0) {
            // groups
            for (let g = 0; g < this.edit_fields.length; g++) {
                const group = this.edit_fields[g];
                // fields
                // @ts-ignore
                for (let f = 0; f < group.fields.length; f++) {
                    // @ts-ignore
                    const field = group.fields[f];
                    if (this.isFieldInModel(field.name, field_list)) {
                        field['model'] = {obj: model, key: field.name};
                        field['placeholder'] = this.getNameForField(field.name);
                        field['description'] = this.getDescriptionForField(field.name);
                        fields.push(field);
                    }
                }
            }
        }
        return fields;
    }

    private isFieldInModel(field_name: string, model: any) {
        return model.hasOwnProperty(field_name);
    }

    /**
     * default function to save all changed records from table
     */
    public saveAllChangedRows($event: any, comment?: string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            // create promise array
            const save_array = [];
            for (let i = 0; i < $event.rows.length; i++) {
                const row = $event.rows[i].row;
                // @ts-ignore
                const record = this.getStore().getRecordByData(row.org_data);
                this.beforeSaveRow(record);
                // this.getStore().updateRecordDataByRecord(record, row.data);
                // @ts-ignore
                save_array.push(this.getStore().patchRecord(record, comment));
            }
            Promise.all(save_array).then((result) => {
                let success = true;
                for (let i = 0; i < result.length; i++) {
                    if (!result[i]) {
                        success = false;
                    }
                }
                resolve(success);
            });
        });
    }

    /**
     * default function to save all toggled records from table
     **/
    public toggleActivationState($event: any) {
        return new Promise<any>((resolve, reject) => {
            // create promise array
            const save_array = [];
            const selection = $event.selection;
            const new_active_state = $event.new_active_state;
            if (selection.selected.length > 0) {
                for (let i = 0; i < selection.selected.length; i++) {
                    const rec = selection.selected[i];
                    if (rec) {
                        // @ts-ignore
                        const real_record = this.getStore().getRecordById(rec.data.auto_id);
                        real_record.data.active = new_active_state;
                        // @ts-ignore
                        save_array.push(this.getStore().patchRecord(real_record));
                    }
                }
            }
            Promise.all(save_array).then((result) => {
                let success = true;
                for (let i = 0; i < result.length; i++) {
                    if (!result[i]) {
                        success = false;
                    }
                }
                resolve(success);
            });
        });
    }

    public getNameForField(field: string) {
        const ar_field_def = this.column_definition.find((x: any) => {
            // @ts-ignore
            return x.id === field;
        });
        if (ar_field_def) {
            // @ts-ignore
            return ar_field_def.label;
        }
        return field;
    }

    public getDescriptionForField(field: string) {
        const ar_field_def = this.column_definition.find((x: any) => {
            return x.id === field;
        });
        // @ts-ignore
        if (ar_field_def && ar_field_def.description) {
            // @ts-ignore
            return ar_field_def.description;
        }
        return '';
    }

    public setCustomErrorCallback(callback: any) {
        this.custom_error_callback = callback;
    }

    /*
    public loadStore(): Promise<any> {
        // ToDO load forms
        return new Promise<any>(function (resolve, reject) {
            this.loadRequiredStores().then((result) => {
                if (result) {
                    // resolve(records);
                    resolve(false);
                } else {
                    resolve(false);
                }
            });
        });
    }

    private loadRequiredStores() {
        return new Promise<any>(function (resolve, reject) {
            resolve(true);
        });
    }
    */

    public beforeSaveRow(row: any) {
        return row;
    }

    /**
     * get name for Bst id
     */
    public getNameForSelectBoxEntry(list_type: string, key: any) {
        // @ts-ignore
        const entry = this.select_boxes[list_type + '_select'].find((x: any) => {
            return x.value === key || Number(x.value) === Number(key);
        });
        if (entry) {
            return entry.name;
        }
        return key;
    }

    unloadStores(): void {
        for (const [key, value] of Object.entries(this.stores)) {
            console.log('unload', key);
            // @ts-ignore
            this.stores[key].unloadStore();
        }
    }

    public parseRecordsToGroupSelectBox(store: Store, valueKey: string, labelKey: string, groupKey: string) {
        const select_options = [];
        const records = store.getRecords();
        const tmpOptionsObj: any = {};
        const tmpOptions: any[] = [];
        for (let i = 0; i < records.length; i++) {
            const rec = records[i];
            if (!tmpOptionsObj[rec.data[groupKey]]) {
                tmpOptionsObj[rec.data[groupKey]] = {
                    items: [],
                    label: rec.data[groupKey]
                };
            }
            tmpOptions.push({
                value: rec.data[valueKey],
                label: rec.data[labelKey],
                group: rec.data[groupKey]
            });
        }
        for (let i = 0; i < tmpOptions.length; i++) {
            tmpOptionsObj[tmpOptions[i].group].items.push({
                value: tmpOptions[i].value,
                label: tmpOptions[i].label
            });
        }

        for (const [key, value] of Object.entries(tmpOptionsObj)) {
            select_options.push(value);
        }
        return select_options;
    }
}
