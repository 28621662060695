<div>
    <app-content-container [content_template]="page_content" [is_loading]="is_loading"
                           [loading_text]="loading_text" [custom_class]="'no-padding'"></app-content-container>
</div>
<ng-template #page_content>

    <div class="padding">
        <div class="guide-side-menu">
            <div class="guide-toc card padding">
                <ol>
                    <li (click)="scrollTo('cargo')">Cargo</li>
<!--                    <li (click)="scrollTo('cargo_t1')" class="level-1">T1</li>-->
<!--                    <li (click)="scrollTo('cargo_t2')" class="level-1">T2</li>-->
<!--                    <li (click)="scrollTo('cargo_t3')" class="level-1">T3</li>-->
                    <li (click)="scrollTo('ore')">Ore</li>
<!--                    <li (click)="scrollTo('cargo_t1')" class="level-1">T1</li>-->
<!--                    <li (click)="scrollTo('cargo_t2')" class="level-1">T2</li>-->
<!--                    <li (click)="scrollTo('cargo_t3')" class="level-1">T3</li>-->
                </ol>
            </div>
            <div class="card guide-toc padding" *ngIf="activeVersion">
                Game Version: {{activeVersion}}
            </div>
        </div>
        <div class="guide-content-container">
            <section id="cargo">
                <div class="card card-1 padding table-wrapper" *ngIf="ScCargoOverview">
                    <app-fb-forms-table [storeObj]="ScCargoOverview"
                                        [storeExtObj]="{version_id: activeVersionId}"
                                        [edit_allowed]="false"
                                        [stateKey]="'bot-cargo-overview'"
                                        [show_add]="false"
                                        [show_delete]="false"
                                        [title]="'Cargo'"
                                        [expand_tpl]="expand_tpl"
                                        [expandAble]="true"
                    >
                    </app-fb-forms-table>
                </div>
            </section>
            <section id="ore">
                <div class="card card-1 padding table-wrapper" *ngIf="ScOreOverview">
                    <app-fb-forms-table [storeObj]="ScOreOverview"
                                        [storeExtObj]="{version_id: activeVersionId}"
                                        [edit_allowed]="false"
                                        [stateKey]="'bot-ore-overview'"
                                        [show_add]="false"
                                        [show_delete]="false"
                                        [title]="'Ore'"
                                        [expand_tpl]="expand_tpl"
                                        [expandAble]="true"
                    >
                    </app-fb-forms-table>
                </div>
            </section>

<!--            <section id="cargo">-->
<!--                <h3 class="group-caption">Cargo</h3>-->
<!--                <div class="card padding">-->
<!--                    <p-table [value]="cargoRows">-->
<!--                        <ng-template pTemplate="header">-->
<!--                            <tr>-->
<!--                                <th pSortableColumn="data.name">Name<p-sortIcon field="data.name"></p-sortIcon></th>-->
<!--                                <th pSortableColumn="data.price">Value<p-sortIcon field="data.price"></p-sortIcon></th>-->
<!--                                <th pSortableColumn="data.tier">Tier<p-sortIcon field="data.tier"></p-sortIcon></th>-->
<!--                            </tr>-->
<!--                        </ng-template>-->
<!--                        <ng-template pTemplate="body" let-cargoRow>-->
<!--                            <tr>-->
<!--                                <td>{{cargoRow.data.name}}</td>-->
<!--                                <td>{{cargoRow.data.price | number: '1.0-0': 'de-DE'}}</td>-->
<!--                                <td>{{cargoRow.data.tier}}</td>-->
<!--                            </tr>-->
<!--                        </ng-template>-->
<!--                    </p-table>-->
<!--                </div>-->
<!--            </section>-->
<!--            <section id="ore">-->
<!--                <h3 class="group-caption">Ore</h3>-->
<!--                <div class="card padding">-->
<!--                    <p-table [value]="oreRows">-->
<!--                        <ng-template pTemplate="header">-->
<!--                            <tr>-->
<!--                                <th pSortableColumn="data.name">Name<p-sortIcon field="data.name"></p-sortIcon></th>-->
<!--                                <th pSortableColumn="data.price">Value<p-sortIcon field="data.price"></p-sortIcon></th>-->
<!--                                <th pSortableColumn="data.tier">Tier<p-sortIcon field="data.tier"></p-sortIcon></th>-->
<!--                            </tr>-->
<!--                        </ng-template>-->
<!--                        <ng-template pTemplate="body" let-oreRow>-->
<!--                            <tr>-->
<!--                                <td>{{oreRow.data.name}}</td>-->
<!--                                <td>{{oreRow.data.price | number: '1.0-0': 'de-DE'}}</td>-->
<!--                                <td>{{oreRow.data.tier}}</td>-->
<!--                            </tr>-->
<!--                        </ng-template>-->
<!--                    </p-table>-->
<!--                </div>-->
<!--            </section>-->
        </div>
        <div style="clear: both;"></div>
    </div>
</ng-template>

<ng-template #expand_tpl let-row>
    <app-commodity-price-details [uex_id]="row.data.uex_id"></app-commodity-price-details>
</ng-template>
