import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FbFormObject} from '../../../../class/fb_form_object';
import {Subscription} from 'rxjs';
import {Store} from '../../../../class/store';
import {StoreService} from '../../../../services/store.service';
import {AuthService} from '../../../../services/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NotificationService} from '../../../../services/notification.service';
import {MatDialog} from '@angular/material/dialog';
import {AppSettings} from '../../../../config/AppSettings';
import {LapTimeUnconfirmed} from '../../../../forms/lap-time-unconfirmed';

@Component({
    selector: 'app-lap-times-unconfirmed',
    templateUrl: './lap-times-unconfirmed.component.html',
    styleUrl: './lap-times-unconfirmed.component.css'
})
export class LapTimesUnconfirmedComponent implements OnInit, OnDestroy, AfterViewInit {


    @ViewChild('select_caption_tpl', {static: true}) set select_caption_tpl(value: ElementRef) {
        this._select_caption_tpl = value;
        // this.setColumns();
        this.cdr.markForCheck();
    }


    public LapTimesUnconfirmed: FbFormObject;
    private subs: Array<Subscription> = [];

    public loading_text = '';
    public is_loading = false;
    public rows: any[];


    private _select_caption_tpl: ElementRef;

    public column_definition: any[];
    public is_admin: boolean;
    public custom_obj: any;

    private channelStore: Store;

    constructor(private storeService: StoreService,
                private authService: AuthService,
                private router: Router,
                private cdr: ChangeDetectorRef,
                private notificationService: NotificationService,
                public dialog: MatDialog,
                private route: ActivatedRoute) {
    }

    ngOnInit() {
    }

    ngAfterViewInit(): void {
        this.is_admin = this.authService.isAdmin() || this.authService.hasRole(AppSettings.ifa_guild_id, AppSettings.group_page_admin)
            || this.authService.hasRole(AppSettings.ifa_guild_id, AppSettings.group_rally_admin);

        this.LapTimesUnconfirmed = new LapTimeUnconfirmed(this.storeService, this.authService, 'edit');
        this.cdr.detectChanges();
    }


    public rowSelected($event: any) {
        let url = '';
        if ($event.row.series_track_assign_id && $event.row.series_track_assign_id > 0) {
            url = 'rally/lap-times/' +
                $event.row.track_id + '/' + $event.row.race_type_id + '/' + $event.row.rt_id +
                '/' + $event.row.series_id + '/' + $event.row.series_track_assign_id;
        } else {
            url = 'rally/lap-times/' +
                $event.row.track_id + '/' + $event.row.race_type_id + '/' + $event.row.rt_id;
        }

        const route = url;
        if ($event.in_new_tab) {
            const url = this.router.createUrlTree([route]);
            const serializedUrl = this.router.serializeUrl(url);

            // Insert `#` between the domain and route
            const modifiedUrl = '#/' + serializedUrl;
            window.open(modifiedUrl, '_blank');
        } else {
            this.router.navigateByUrl(route);
        }
    }

    ngOnDestroy(): void {
        this.subs.forEach(s => s.unsubscribe());
    }
}
